.card-footer{
    position: fixed;
    bottom: 0;
    background-color: blue;
}

.card-body{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 18px;
}

/* .card-body > ul{
    padding-left: 0;
} */

.card-title{
    font-size: 22px;
    min-height: 50px;
}

.card-category{
    min-height: 30px;
}

.features{
    min-height: 80px;
}

.material{
    min-height: 67px;
}

.bold-container{
    font-size: medium;
}