.card-container{
    box-shadow: 2px 2px 5px #959EA5;
    overflow: hidden;
    width: 70%;
    border-end-end-radius: 5px;
}

.row{
    overflow-y: auto;
    display: flex;
    justify-content: center;
    height: 80vh;
}

.suggestion-container{
    padding: 5px;
    width: 30%;
    box-shadow: 2px 2px 5px #959EA5;
    white-space: pre-wrap;
    color: black;
    background-color: rgb(235, 235, 235);
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
}


.main-container{
    background-color: white;
    height: 100vh;
    display: flex;
    gap: 30px;
    /* overflow: hidden; */
}

.main-title{
    text-align: center;
    background-color: black;
    color: whitesmoke;
    width: 100%;
    font-size: 30px;
}

.buttons-container{
    gap: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    font-weight: 900;
}

.nextBtn{
    text-align: right;
}

.consolidation-container{
    box-shadow: 2px 2px 5px #959EA5;
    font-size: 14px;
    height: auto;
    padding: 20px;
    margin: 15px 0px 15px 15px;
    margin-left: 10px;
    margin-right: 15px;
    color: black;
    background-color: white;
}

.row::-webkit-scrollbar, .suggestion-container::-webkit-scrollbar{
    width: 20px;               /* width of the entire scrollbar */
}

.suggestion-container::-webkit-scrollbar{
    width: 10px;               /* width of the entire scrollbar */
}
  
.row::-webkit-scrollbar-track, .suggestion-container::-webkit-scrollbar-track {
    background:  rgb(193, 192, 192);        /* color of the tracking area */
}
  
.row::-webkit-scrollbar-thumb, .suggestion-container::-webkit-scrollbar-thumb {
    background-color:rgb(45, 44, 44);    /* color of the scroll thumb */      /* roundness of the scroll thumb */
    border: 10px solid rgb(61, 61, 61);  /* creates padding around scroll thumb */
}